import React, { useState, useEffect } from 'react';
import { sendMessageToUser } from '../../../api/Adminapi'; // Import the API call function
import Loader from '../../../common/loader/Customloader';

interface SupportMessage {
  id: number;
  user_id: string;
  subject: string;
  messages: string; 
  name: string;
  email: string;  
}

interface ModalProps {
  message: SupportMessage | null;
  isOpen: boolean;
  onClose: () => void;
}


const SupportMessageModal: React.FC<ModalProps> = ({ message, isOpen, onClose }) => {
  const [adminReply, setAdminReply] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messagesArray, setMessagesArray] = useState<{ type: string; message: string; timestamp: string }[]>([]);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (message) {
      try {
        const parsedMessages = JSON.parse(message.messages);
        if (Array.isArray(parsedMessages)) {
          setMessagesArray(parsedMessages);
        } else {
          console.error('Parsed messages is not an array:', parsedMessages);
        }
      } catch (error) {
        console.error('Failed to parse messages:', error);
      }
    }
  }, [message]);

  if (!isOpen || !message) return null;

  const handleAdminReplyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAdminReply(e.target.value);
    if (e.target.value.trim() !== '') {
      setError('');
    }
  };
  const handleSubmit = async () => {
    if (adminReply.trim() === '') {
      setError('Please enter a message before submitting.');
      return;
    }
  
    const newAdminMessage = {
      type: 'admin',
      message: adminReply.trim(),
      timestamp: new Date().toISOString(),
    };
  
    // Optimistically add the message to the UI
    const updatedMessagesArray = [...messagesArray, newAdminMessage];
    setMessagesArray(updatedMessagesArray); // Immediately reflect the change
  
    setIsLoading(true);
    setSuccessMessage('');
  
    try {
      const messageData = {
        user_id: message.user_id, 
        id: message.id,
        messages: JSON.stringify(updatedMessagesArray),
        userName: message?.name,
        userEmail: message?.email,
        userSubject: message?.subject,
      };
  
      const response = await sendMessageToUser(messageData);
  
      if (response?.status === 200) {
        setSuccessMessage('Your message has been successfully sent.');
      } else {
        setError('Failed to send message.');
        // Optionally, you can revert the optimistic update here
        setMessagesArray(messagesArray); // Restore the old messages if failed
      }
      setAdminReply('');
    } catch (error) {
      console.error('Failed to send message:', error);
      setError('An error occurred while sending the message.');
      // Optionally, you can revert the optimistic update here
      setMessagesArray(messagesArray); // Restore the old messages if failed
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSuccess = () => {
    setSuccessMessage('');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      {successMessage ? (
        <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 fade-in-animate">
          <div className="flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
            <div
              className="bg-white rounded-lg max-w-sm w-full mx-4 p-4 flex flex-col text-center"
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="text-xl font-bold text-purpletacx">Success!</h3>
              <p className="mt-2 text-black">{successMessage}</p>
              <button
                className="mt-4 px-4 py-2 bg-[#594255] text-white rounded-md"
                onClick={handleCloseSuccess}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg relative">
          <h2 className="text-lg font-bold text-center bg-[#F7F7F7] p-2 rounded mb-4">
           {message.subject}
          </h2>

          <div className="space-y-4 overflow-y-auto max-h-60">
            {/* Chat messages */}
            {messagesArray.map((msg, index) => (
              <div
                key={index}
                className={`flex ${msg.type === 'admin' ? 'justify-end' : 'justify-start'} mb-2`}
              >
                <div
                  className={`max-w-xs px-4 py-2 rounded-lg ${msg.type === 'admin' ? 'bg-[#594255] text-white' : 'bg-[#f1f1f1] text-black'}`}
                >
                  <p>{msg.message}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-4">
  {/* <label className="block font-bold text-black">Reply:</label> */}
  <textarea
    value={adminReply}
    onChange={handleAdminReplyChange}
    placeholder="Enter your message here..."
    className={`border rounded-lg w-full p-2 ${error ? 'border-red-500' : ''}`}
    rows={2} 
  ></textarea>
  {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
</div>


          <div className="mt-1 flex justify-end space-x-2">
            <button
              onClick={onClose}
              className="bg-red-600 text-white px-4 py-2 rounded-lg duration-200"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="bg-[#594255] text-white px-4 py-2 rounded-lg duration-200"
              disabled={isLoading}
            >
              {isLoading ? 'Sending...' : 'Send'}
            </button>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center p-4">
          <Loader />
        </div>
      )}
    </div>
  );
};
export default SupportMessageModal;
