import Logo from '../../images/logo/TACX Brown Logo.svg';
const CustomLoader = () => {
  return (
    <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h-screen bg-[#59425580]">
      <div className="relative flex items-center justify-center">
        <div className="rounded-full bg-white p-4 flex items-center justify-center">
          <div className="absolute flex items-center justify-center">
            <div className="h-34 w-34 animate-spin rounded-full border-8 border-solid border-[#DFC749] border-t-transparent"></div>
          </div>
          <img src={Logo} alt="Loading..." className="h-24 w-24 relative z-10" />
        </div>
      </div>
    </div>
  );
};

export default CustomLoader;

