import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layout/Defaultlayout';
import {
  getEmailTemplate,
  postEmailTemlate,
  updateEmailTemplate,
} from '../../../api/Adminapi';
import Loader from '../../../common/loader/Index';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'ckeditor5/ckeditor5.css';

const UniqueSupportCard: React.FC = () => {
  const [emailTemplates, setEmailTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newTemplate, setNewTemplate] = useState({
    Title: '',
    subject: '',
    body: '',
  });
  const [currentTemplate, setCurrentTemplate] = useState<any | null>(null);

  const fetchEmailTemplates = async () => {
    try {
      setLoading(true);
      const res = await getEmailTemplate();
      const templates = res?.data?.text?.EmailTemplate[0] || [];
      setEmailTemplates(templates);
    } catch (error) {
      console.error('Fetch Email Template Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  const handleSaveNewTemplate = async () => {
    if (newTemplate.Title && newTemplate.subject && newTemplate.body) {
      try {
        setLoading(true);
        const payload = {
          title: newTemplate.Title,
          subject: newTemplate.subject,
          body: newTemplate.body,
        };
        const response = await postEmailTemlate(payload);

        if (response && response.data.success) {
          await fetchEmailTemplates();
          setIsAddingNew(false);
          setNewTemplate({ Title: '', subject: '', body: '' });
        } else {
          console.error('Error saving the template:', response?.data.message);
        }
      } catch (error) {
        console.error('API Error while saving template:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTemplateClick = (id: number) => {
    setSelectedTemplate(id);
    setIsAddingNew(false);
    const templateData = emailTemplates.find(template => template.id === id);
    setCurrentTemplate(templateData); // Set the current template data
  };

  const changeEmailTemplate = async () => {
    if (currentTemplate) {
      try {
        setLoading(true);
        const response = await updateEmailTemplate(currentTemplate);

        if (response && response.data.success) {
          setEmailTemplates((prevTemplates) =>
            prevTemplates.map((template) =>
              template.id === currentTemplate.id
                ? { ...template, ...currentTemplate }
                : template,
            ),
          );
        } else {
          console.error('Error updating the template:', response?.data.message);
        }
      } catch (error) {
        console.error('API Error while updating template:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAddNewTemplate = () => {
    setSelectedTemplate(null);
    setIsAddingNew(true);
    setNewTemplate({ Title: '', subject: '', body: '' });
    setCurrentTemplate(null); // Reset current template
  };

  const handleUpdateTemplate = (field: 'Subject' | 'Body', value: string) => {
    if (currentTemplate) {
      setCurrentTemplate((prev:any) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleCKEditorChange = (_event: any, editor: any) => {
    const data = editor.getData();
    setNewTemplate({ ...newTemplate, body: data });
    if (currentTemplate) {
      setCurrentTemplate({ ...currentTemplate, body: data });
    }
  };

  return (
    <DefaultLayout title="Email Template">
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white shadow-lg rounded-lg p-6 md:flex md:gap-4">
          <div className="w-full md:w-1/3">
            <h2 className="text-lg font-semibold mb-4">Templates</h2>
            <ul>
              {emailTemplates.map((template) => (
                <li
                  key={template.id}
                  onClick={() => handleTemplateClick(template.id)}
                  className={`cursor-pointer p-2 rounded-md mb-2 w-full md:w-[80%] lg:w-[50%] ${
                    selectedTemplate === template.id
                      ? 'bg-[#5A4355] text-white'
                      : 'bg-gray-100 hover:bg-gray-200'
                  }`}
                >
                  {template.Title}
                </li>
              ))}
            </ul>
            <button
              className="w-full md:w-[80%] lg:w-[50%] bg-customHover text-white p-2 rounded-md mt-4 hover:bg-yellowtacx hover:text-black"
              onClick={handleAddNewTemplate}
            >
              Add New Template
            </button>
          </div>

          <div className="w-full md:w-2/3 md:pl-4 mt-6 md:mt-0">
            {isAddingNew ? (
              <>
                <h2 className="text-lg font-semibold mb-4">Add New Template</h2>
                <div className="mb-4">
                  <label
                    htmlFor="newTitle"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Template Title
                  </label>
                  <input
                    type="text"
                    id="newTitle"
                    value={newTemplate.Title}
                    onChange={(e) =>
                      setNewTemplate({ ...newTemplate, Title: e.target.value })
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    placeholder="Enter template title"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="newSubject"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="newSubject"
                    value={newTemplate.subject}
                    onChange={(e) =>
                      setNewTemplate({
                        ...newTemplate,
                        subject: e.target.value,
                      })
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    placeholder="Enter email subject"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="newBody"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Body
                  </label>
                  <div className='p-4 border border-gray-300 rounded-md'>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed'],
                      
                      
                    }}
                    data={newTemplate.body}
                    onChange={handleCKEditorChange}
                  />
                  </div>
                </div>
                <button
                  className="w-[50%] bg-customHover text-white p-2 rounded-md mt-4 hover:bg-yellowtacx hover:text-black"
                  onClick={handleSaveNewTemplate}
                >
                  Save New Template
                </button>
              </>
            ) : currentTemplate ? (
              <>
                <h2 className="text-lg font-semibold mb-4">Edit Template</h2>
                <div className="mb-4">
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    value={currentTemplate.Subject}
                    onChange={(e) =>
                      handleUpdateTemplate('Subject', e.target.value)
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    placeholder="Enter email subject"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="body"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Body
                  </label>
                  <div className='p-4 border border-gray-300 rounded-md'>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed'], // Removes image upload options
                     
                     
                    }}
                    data={currentTemplate.Body}
                    onChange={(_event, editor) => {
                      const data = editor.getData();
                      handleUpdateTemplate('Body', data);
                    }}
                  />
                  </div>
                </div>
                <button
                  onClick={changeEmailTemplate}
                  className="w-[50%] bg-customHover text-white p-2 rounded-md mt-4 hover:bg-yellowtacx hover:text-black"
                >
                  Save Changes
                </button>
              </>
            ) : (
              <p className="text-gray-500">Select a template to edit.</p>
            )}
          </div>
        </div>
      )}
    </DefaultLayout>
  );
};

export default UniqueSupportCard;
