import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layout/Defaultlayout';
import { getTheUserSupport } from '../../../api/Adminapi';
import { FaSearch } from 'react-icons/fa';
import SupportMessageModal from '../Components/ChatModal';
import Pagination from '../../../components/pagination/Pagination'; // Import the Pagination component

interface SupportMessage {
  id: number;
  user_id: string;
  name: string;
  email: string;
  phone: string;
  subject: string;
  messages: string; // JSON string containing admin messages
  createdAt: string;
}

const UniqueSupportCard: React.FC = () => {
  const [supportMessages, setSupportMessages] = useState<SupportMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(5); // Items per page
  const [selectedMessage, setSelectedMessage] = useState<SupportMessage | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchDataOfUserSupport();
  }, []);

  const fetchDataOfUserSupport = async () => {
    try {
      setLoading(true);
      const response = await getTheUserSupport();
      setSupportMessages(response?.data.user || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Pagination logic
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const filteredMessages = supportMessages.filter(
    (message) =>
      message.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.subject?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const currentMessages = filteredMessages.slice(
    indexOfFirstMessage,
    indexOfLastMessage,
  );

  const totalMessages = filteredMessages.length;
  const totalPages = Math.ceil(totalMessages / messagesPerPage);

  const handleViewDetails = (message: SupportMessage) => {
    setSelectedMessage(message);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMessage(null);
  };

  const extractUserMessages = (messages: string) => {
    try {
      const parsedMessages = JSON.parse(messages);
      if (!Array.isArray(parsedMessages)) {
        throw new Error('Parsed messages is not an array');
      }
      const userMessages = parsedMessages
        .filter((msg: { type: string }) => msg.type === 'user')
        .map((msg: { message: string }) => msg.message);

      return userMessages.length > 0
        ? userMessages.join('\n')
        : 'No user messages found';
    } catch (error) {
      console.error('Error parsing messages:', error);
      return 'No message available';
    }
  };

  return (
    <DefaultLayout title="User Support">
      <div className="bg-white shadow-lg rounded-lg">
        <div className="flex flex-col sm:flex-row justify-between items-center bg-[#F7F7F7] p-4 rounded-lg">
          <p className="font-medium text-md text-black">
           Requests to support
          </p>
          <div className="flex flex-col sm:justify-end sm:flex-row sm:items-center w-full sm:w-[70%] sm:space-x-4">
            {/* Search Bar */}
            <div className="relative w-full xl:w-[50%] sm:w-full mb-4 sm:mb-0">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
                className="p-2 border border-stroke rounded-xl pr-20 w-full bg-white text-gray-800"
              />
              <FaSearch className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto animate-fade-in scrollbar-custom p-2">
        <table className="min-w-full rounded-lg overflow-hidden">
  <thead>
    <tr className="hover:bg-gray-50 transition duration-200 ">
      <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-12 sm:w-16">No</th>
      <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-24 sm:w-32">User Name</th>
      <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-36 sm:w-48">Email</th>
      <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-28 sm:w-36">Contact No</th>
      <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-20 sm:w-32">Subject</th>
      <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-full sm:w-[15%]">Message</th>
      <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-24 sm:w-28">Action</th>
    </tr>
  </thead>
  <tbody>
    {currentMessages.length > 0 ? (
      currentMessages.map((message, index) => (
        <tr key={message.id} className="hover:bg-gray-50 transition duration-200">
          <td className="py-3 px-5 border-b border-gray whitespace-nowrap">{indexOfFirstMessage + index + 1}</td>
          <td className="py-3 px-5 border-b border-gray whitespace-nowrap">
            <span className="text-gray-800 font-medium">{message.name}</span>
          </td>
          <td className="py-3 px-5 border-b border-gray whitespace-nowrap">{message.email}</td>
          <td className="py-3 px-5 border-b border-gray whitespace-nowrap">{message.phone || 'N/A'}</td>
          <td className="py-3 px-5 border-b border-gray whitespace-nowrap">{message.subject || 'N/A'}</td>
          <td className="py-3 px-5 border-b border-gray">
  {(() => {
    const messageText = extractUserMessages(message.messages).trim();
    const words = messageText.split(/\s+/); // Split by spaces and handle extra spaces
    return words.length > 20
      ? words.slice(0, 20).join(' ') + '...'
      : messageText;
  })()}
</td>

          <td className="py-3 px-5 border-b border-gray whitespace-nowrap">
            <button
              onClick={() => handleViewDetails(message)}
              className="bg-purpletacx flex justify-center items-center gap-1 text-white text-sm rounded-lg py-1.5 w-full hover:bg-yellowtacx transition duration-200"
            >
              <p>Reply</p>
            </button>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={7} className="py-4 text-center">No users found.</td>
      </tr>
    )}
  </tbody>
</table>

        </div>

        {/* Pagination component */}
        {totalMessages > messagesPerPage && (
           <div className="p-3">
          <Pagination
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            startIndex={indexOfFirstMessage}
            endIndex={indexOfLastMessage}
            totalItems={totalMessages}
          />
           </div>
        )}

        <SupportMessageModal
          message={selectedMessage}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      </div>
    </DefaultLayout>
  );
};

export default UniqueSupportCard;
